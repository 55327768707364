@charset "utf-8";

$color-key-hover: #9789ab;
$color-sub: #000;
$color-background: #EAEAEA;
$color-background-light: #FAF7FF;
$color-background-gray: #EFEFEF;
$color-background-dark: #292929;
$color-button-cancel: #868686;
$color-close-box: #C70E0E;
$color-button: #4B23A5;
$color-button-create: #E1872D;
$color-error: #C70E0E;
$color-confirm: #F1EFFC;
$color-border: #707070;
$color-table-th-bg: #f6f6f6;
$color-title: #665f6f;
$color-title-back: #dacfea;
$color-subject: #665F6F;
$color-subject-back: #ece4fa;
$color-footer-back: #F5F5F5;
$color-dark-gray: #676b6c;
$color-theme: #75BCC9;
$color-main-deep: #3F5393;
$color-main-light: #ebebeb;
$color-default: #B9B6B6;
$color-closed: #B9B6B6;
$color-success: #3A9F2E;
$color-info: #F5C910;
$color-warning: #ED6A18;
$color-focus: #ED6A18;
$color-danger: #DE1B50;
$color-light-green: #D9EDE6;
$color-text-white: #ffffff;
$color-text-lightGray: #F4EFEF;
$color-text-lightGray: #3b3f41;
$color-button-main: #75BCC9;

// ↓↓↓ fire-rocket用（旧）
$color-key: #F7B52C;
$color-light-yellow: #FFFA79;
$color-form-label:#6C6C6C;
$color-form-border:#AAAAAA;
$color-form-bg:#F2F9FF;
$color-form-placeholder: #AAAAAA;
$color-form-error: #F72C2C;
$color-form-error-bg: #FBECE7;
$color-text-main: #6C6C6C;
$color-text-light: #F5F5F5;
$color-text-dark: #000000;
$color-text-link: #2196F3;
$color-button-danger: #F72C2C;
$color-button-focus: #E95513;
$color-sub-title: #CC9E47;



//fire-rocket カラーパターン（新） (2023/01/17　後藤)
$color-main: #F7B52C;
$color-sub: #FFDE7B;
$color-third: #EEEEEE;

$color-more: #E95513;

$color-main-opacity-10: rgba(247, 181, 44, 0.1);
$color-main-opacity-20: rgba(247, 181, 44, 0.2);
$color-sub-opacity-10: rgba(255, 222, 123, 0.1);
$color-sub-opacity-20: rgba(255, 222, 123, 0.2);
$color-sub-opacity-30: rgba(255, 222, 123, 0.3);
$color-sub-opacity-40: rgba(255, 222, 123, 0.4);
$color-sub-opacity-60: rgba(255, 222, 123, 0.6);

$color-background-main: #FFFFFF;
$color-background-sub: #F1F1F4;
$color-background-third: #F8F8FB;
$color-background-c-notice: #FFECB9;
$color-background-more-key: #E95513;
$color-background-link: #7493E5;
$color-background-black: #000000;

$color-background-more-key-opacity-05: rgba(233, 85, 19, 0.05);
$color-background-link-opacity-05: rgba(116, 147, 229, 0.05);
$color-background-third-opacity-40: rgba(248, 248, 251, 0.4);


$color-sideNavi-main: #2D2D2D;
$color-sideNavi-sub: #434343;
$color-sideNavi-text: #ECECEC;

$color-textLight-main: #1A1A1C;
$color-textLight-sub: #6A6A6A;
$color-textLight-third: #757578;
$color-textLight-link: #7493E5;
$color-textLight-alert: #EC0000;
$color-textLight-more-key: #E95513;

$color-textDark-main: #FFFFFF;
$color-textDark-sub: #B4B4B7;
$color-textDark-third: #949497;
$color-textDark-link: #7096F8;
$color-textDark-alert: #FF4B36;
$color-text-placeholder: #BFBFBF;


$color-line-gray: #DADADA;
$color-signUp-bg: #E95513;
$color-cancel: #C9C8C8;

$color-scrollbar-base: #757578;
$color-scrollbar-track: #EEEEEE;

$color-notice-info: rgba(187, 226, 255, 0.2);
$color-notice-success: rgba(164, 223, 143, 0.2);
$color-notice-danger: rgba(237, 158, 133, 0.2);
$color-notice-black: rgba(106, 106, 106, 0.2);

//fire-rocket フォント
//[font-weight] [font-size]/[line-height] [font-family]

$font-desktop-H1: bold 42px/1.5 'Inter';
$font-desktop-H2: bold 32px/1.5 'Inter';
$font-desktop-H3: bold 26px/1.5 'Inter';
$font-desktop-H4: bold 22px/1.5 'Inter';
$font-desktop-H5: bold 20px/1.5 'Inter';
$font-desktop-H6: bold 18px/1.5 'Inter';

$font-desktop-cap: normal 14px/1.75 'Inter';
$font-desktop-cap-B: bold 14px/1.75 'Inter';
$font-desktop-cap_small_R: normal 12px/1.75 'Inter';
$font-desktop-cap_small_B: bold 12px/1.75 'Inter';
$font-desktop-button: bold 16px/1.5 'Inter';
$font-desktop-Body16: normal 16px/1.75 'Inter';
$font-desktop-BodyB: bold 16px/1.5 'Inter';
$font-desktop-not-found: normal 80px/1.5 'Inter';
$font-desktop-examination-headline: bold 40px/1.0 'Inter';
$font-desktop-examination-percentage: bold 62px/1.0 'Inter';
$font-desktop-mokuji_m: bold 20px/1.15 'Inter';
$font-desktop-0617_button: bold 19px/1.5 'Inter';


$font-mobile-cap: normal 12px/1.75 'Inter';
$font-mobile-button_B: bold 16px/1.5 'Inter';
$font-mobile-body: normal 16px/1.75 'Inter';
$font-mobile-body2: normal 14px/1.75 'Inter';
$font-mobile-H7: bold 14px/1.5 'Inter';
$font-mobile-H6: bold 16px/1.5 'Inter';
$font-mobile-H5: bold 18px/1.5 'Inter';
$font-mobile-H4: bold 20px/1.5 'Inter';
$font-mobile-H3: bold 22px/1.5 'Inter';
$font-mobile-H1: bold 26px/1.5 'Inter';
$font-mobile-overline: normal 10px/1.75 'Inter';
$font-mobile-overline_B: bold 10px/1.75 'Inter';
$font-mobile-tab-title: 600 8px/1.2 'Inter';
$font-mobile-tag: normal 8px/1.75 'Inter';
$font-mobile-subTitle: normal 16px/1.5 'Inter';
$font-mobile-mokuji_m: bold 13px/1.2 'Inter';
$font-mobile-8-bold: bold 8px/1.2 'Inter';
$font-mobile-large-text: bold 32px/1.5 'Inter';


$font-copyRight: normal 11px/1.5 'Inter';

