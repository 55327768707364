$basepoint-sp: 375px;
$breakpoint-xs: 319px;  //  default 319px - iPhone 5/SE 325
$breakpoint-sp: 319px;  //  default 319px - iPhone 6/7/8/x/xs 375
$breakpoint-sm: 413px;  //  default 413px - iPhone 6/7/8 Plus 414
$breakpoint-sm-ex: 430px;
$breakpoint-md: 743px;  //  default 767px - iPad
$breakpoint-pad-ex: 820px;  //  default 959px - iPad
$breakpoint-lg: 960px;  //  default 959px - PC min
$breakpoint-pad: 1024px;  //  default 959px - iPad
$breakpoint-pad2: 1112px;  //  default 959px - iPad pro
$breakpoint-xl: 1279px; // default 1279px - PC max

$breakpoints: (
  'xs': 'screen and (max-width: #{$breakpoint-xs})',
  'sp': 'screen and (max-width: #{$breakpoint-sp})',
  'sm': 'screen and (max-width: #{$breakpoint-sm})',
  'smx': 'screen and (max-width: #{$breakpoint-sm-ex})',
  'md': 'screen and (max-width: #{$breakpoint-md})',
  'mdx': 'screen and (max-width: #{$breakpoint-pad-ex})',
  'lg': 'screen and (max-width: #{$breakpoint-lg})',
  'pad': 'screen and (max-width: #{$breakpoint-pad})',
  'pad-pro': 'screen and (max-width: #{$breakpoint-pad2})',
  'xl': 'screen and (max-width: #{$breakpoint-xl})',
) !default;

@mixin media($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
