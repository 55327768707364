.c-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 0;
  &-authCode {
    background-color: $color-signUp-bg;
    border: $color-signUp-bg 1px solid;
    border-radius: 10px;
    color: $color-background-main;
    font: $font-desktop-button;
    letter-spacing: 0.04em;
    &:hover {
      opacity: 0.8;
    }
    @include media(md) {
      border-radius: 0;
      font: $font-mobile-button_B;
    }
  }
  &-login {
    background-color: $color-main;
    border: $color-main 1px solid;
    border-radius: 10px;
    color: $color-background-main;
    font: $font-desktop-button;
    letter-spacing: 0.04em;
    &:hover {
      opacity: 0.8;
    }
  }
  &-login-ms {
    gap: 9px;
    background-color: $color-background-main;
    border: $color-textDark-sub 1px solid;
    border-radius: 10px;
    color: $color-textLight-sub;
    font: $font-desktop-button;
    letter-spacing: 0.04em;
    @include media(md) {
      font: $font-mobile-button_B;
    }
    &::before {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      background-image: url('../../../assets/images/logo_ms.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  &-logout {
    background-color: $color-main;
    border: $color-main 1px solid;
    border-radius: 10px;
    color: $color-background-main;
    font: $font-desktop-button;
    letter-spacing: 0.04em;
    &:hover {
      opacity: 0.8;
    }
  }
  &-signUp {
    background-color: $color-signUp-bg;
    border: $color-signUp-bg 1px solid;
    border-radius: 10px;
    color: $color-background-main;
    font: $font-desktop-button;
    letter-spacing: 0.04em;
    &:hover {
      opacity: 0.8;
    }
    @include media(md) {
      font: $font-mobile-button_B;
    }
  }
  &-more {
    background-color: $color-main;
    border: $color-main 1px solid;
    border-radius: 10px;
    color: $color-background-main;
    font-weight: bold;
    font-size: 1.1rem;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 7px;
    &:hover {
      opacity: 0.8;
    }
  }
  &-apply {
    padding-left: 30px;
    background-color: $color-main;
    border: $color-main 1px solid;
    border-radius: 10px;
    color: $color-background-main;
    font-weight: bold;
    font-size: 1.1rem;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 7px;
    &:hover {
      opacity: 0.8;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 5%;
      margin: auto 0;
      width: 30px;
      height:30px;
      background-image: url(../../../assets/images/icon_hand.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  &-primary {
    padding: 9px 16px 9px 16px;
    background-color: $color-main;
    border: $color-main 1px solid;
    border-radius: 8px;
    color: $color-textDark-main;
    font: $font-desktop-button;
    letter-spacing: 0.04em;
    @include media(md) {
      font: $font-mobile-button_B;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  &-cancel {
    padding: 9px 6px;
    background-color: $color-cancel;
    border: $color-textDark-sub 1px solid;
    border-radius: 8px;
    color: $color-textDark-main;
    font: $font-desktop-button;
    letter-spacing: 0.04em;
    &:hover {
      opacity: 0.8;
    }
  }
  &-secondary {
    padding: 9px 16px 9px 16px;
    background-color: $color-background-main;
    border: $color-main 1px solid;
    border-radius: 8px;
    color: $color-main;
    font: $font-desktop-button;
    letter-spacing: 0.04em;
    @include media(md) {
      font: $font-mobile-button_B;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  &-tertiary {
    padding: 9px 16px 9px 16px;
    background-color: $color-textLight-more-key;
    border: $color-textLight-more-key 1px solid;
    border-radius: 8px;
    color: $color-textDark-main;
    font: $font-desktop-button;
    letter-spacing: 0.04em;
    @include media(md) {
      font: $font-mobile-button_B;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  &-disable {
    background-color: $color-third;
    border: $color-third 1px solid;
    border-radius: 8px;
    color: $color-textDark-main;
    font: $font-desktop-button;
    letter-spacing: 0.04em;
    @include media(md) {
      font: $font-mobile-button_B;
    }
  }
  &-create {
    padding: 11px 16px 11px 47px;
    background-color: $color-main;
    border: $color-main 1px solid;
    border-radius: 8px;
    color: $color-textDark-main;
    font: $font-desktop-button;
    letter-spacing: 0.04em;
    &:hover {
      opacity: 0.8;
    }
    &:before {
      content: "";
      position: absolute;
      display: block;
      left: 16px;
      width: 16px;
      height: 16px;
      background-image: url(../../../assets/images/icon_positive_white.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  &-file {
    width: 300px;
    padding: 8px 0 20px 0;
    background-color: $color-main;
    border: $color-main 1px solid;
    border-radius: 8px;
    color: $color-textDark-main;
    font: $font-desktop-button;
    letter-spacing: 0.04em;
    &:hover {
      opacity: 0.8;
    }
  }
  &-warning {
    padding: 0 9px;
    background-color: $color-textLight-alert;
    border: $color-textLight-alert 1px solid;
    border-radius: 8px;
    color: $color-textDark-main;
    font: $font-desktop-button;
    letter-spacing: 0.04em;
    &-bgWhite {
      background-color: $color-background-main;
      border: $color-textLight-more-key 1px solid;
      color: $color-textLight-more-key;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  &-returnTop {
    background-color: $color-main;
    border: $color-textLight-main 1px solid;
    border-radius: 8px;
    color: $color-textLight-main;
    font: $font-desktop-button;
    letter-spacing: 0.04em;
    &:hover {
      opacity: 0.8;
    }
  }
  &-play {
    padding: 9px 30px 9px 16px;
    background-color: $color-main;
    border: $color-main 1px solid;
    border-radius: 8px;
    color: $color-textDark-main;
    font: $font-desktop-button;
    letter-spacing: 0.04em;
    &:hover {
      opacity: 0.8;
    }
    &:after {
      content: "▲";
      position: absolute;
      display: block;
      right: 12px;
      transform: rotate(90deg);
    }
  }
  &-addChapter {
    padding: 9px 16px 9px 47px;
    background-color: $color-background-main;
    border: $color-textDark-sub 1px solid;
    color: $color-textLight-main;
    font: normal 19px/1.5 'Inter';
    letter-spacing: 0.04em;
    &:hover {
      opacity: 0.8;
    }
    &:before {
      content: "";
      position: absolute;
      display: block;
      left: 16px;
      width: 16px;
      height: 16px;
      background-image: url(../../../assets/images/icon_positive_gray.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  &-openChapter {
    padding: 0 16px 0 47px;
    background-color: $color-background-main;
    border: $color-textDark-sub 1px solid;
    color: $color-textLight-main;
    font: normal 19px/1.5 'Inter';
    letter-spacing: 0.04em;
    &:hover {
      opacity: 0.8;
    }
    &:before {
      content: "";
      position: absolute;
      display: block;
      left: 16px;
      width: 16px;
      height: 16px;
      background-image: url(../../../assets/images/icon_lecture_button_closed.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  &-download {
    gap: 6px;
    background-color: $color-main;
    border: $color-main 1px solid;
    border-radius: 10px;
    color: $color-background-main;
    font-weight: bold;
    font-size: 1.1rem;
    @include media(md) {
      gap: 2px;
      font-size: 1rem;
    }
    &:hover {
      opacity: 0.8;
    }
    &::before {
      content: "";
      position: relative;
      margin: auto 0;
      width: 22px;
      height: 22px;
      background-image: url(../../../assets/images/icon_download_white.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  &-pause {
    border: 0;
    background-color: unset;
    background-image: url(../../../assets/images/icon_pause_main.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    &:hover {
      opacity: 0.8;
    }
  }
  &-monotone {
    background-color: $color-background-main;
    border: black 1px solid;
    border-radius: 8px;
    color: $color-textLight-main;
    font: $font-desktop-Body16;
    letter-spacing: 0.04em;
    @include media(md) {
      font: $font-mobile-button_B;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  &-arrow-both {
    padding: 11px 16px 11px 47px;
    background-color: $color-main;
    border: $color-main 1px solid;
    border-radius: 8px;
    color: $color-textDark-main;
    font: $font-desktop-button;
    letter-spacing: 0.04em;
    &:hover {
      opacity: 0.8;
    }
    &:before {
      content: "";
      position: absolute;
      display: block;
      left: 16px;
      width: 20px;
      height: 20px;
      background-image: url(../../../assets/images/arrow_both_white.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}
