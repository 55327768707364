@charset "utf-8";
@import url("//fonts.googleapis.com/css?family=Roboto+Condensed:400,700");
@import url('//fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('//fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('//fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');
@import url('//fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
* {
  box-sizing: border-box;
}
body {
  padding: 0;
  margin: 0!important;
  display: unset;
  font-size: 16px;
  line-height: 1.65em;
  font-weight: normal;
}
ul {
  // width: 0;
  // height: 0;
  margin: 0;
  padding: 0;
}

li {
  // width: 0;
  // height: 0;
  margin: 0;
  padding: 0;
  list-style: none;
}

ul,li,div,span,p,img,a,h1,h2,h3,h4,h5,h6 {
  &:active:focus {
    outline: none;
  }
}
