@charset "utf-8";

.p-saving {
  position: fixed;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 0;
  bottom: 10px;
  width: 50px;
  height: 60px;
  border-radius: 8px 0 0 8px;
  background-color: $color-main;
  z-index: 800;
  font-size: 10px;
  color: $color-text-white;
  animation: savingFadeOut 0.5s forwards;
  opacity: 0;
  &:before {
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position:center center;
    background-image: url("../../../assets/images/icon_autorenew_white.png");
    animation: rotation 3s linear infinite;
  }
  &-show {
    right: 0;
    display: flex;
    opacity: 1;
    animation: savingFadeIn 0.5s forwards;
  }
}
