@charset "utf-8";
@import "../../scss/foundation/foundation_inc";
.l-content {
  position: relative;
  width: 100%;
  //min-width: calc(1280px - 260px);
  margin: 0;
  padding: 0 0 100px 0;
  overflow-x: hidden;
  overflow-y: scroll;
  @include media(md) {
    min-width: unset;
  }
  &__inner {
    position: relative;
    width: 100%;
    max-width: 1440px;
    margin: 70px auto 0 auto;
    padding: 0 70px;
    @include media(lg) {
      padding: 0 32px;
    }
    @include media(md) {
      max-width: unset;
      padding: 0 16px;
      margin: 100px auto 0 auto;
    }
  }
}

.l-content-front {
  position: relative;
  width: 100%;
  min-height: calc(100% - 127px);//ウインドウ高さが高い場合にフッターが上に来ないようにしている
  margin: 0 0 0 0;
  padding: 60px 0 60px 0;
  @include media(md) {
    padding: 60px 0 30px 0;
  }
  &__inner {
    position: relative;
    width: 100%;
    max-width: 1064px;
    margin: 0 auto;
    padding: 0 32px 100px 32px;
    @include media(md) {
      padding: 0 16px 100px 16px;
    }
  }
  &__head {
    width: 100%;
    background-color: $color-background-sub;
    &__kv {
      width: 100%;
      max-width: 1280px;
      height: 32vw;
      max-height: 400px;
      margin: 0 auto;
      overflow: hidden;
      @include media(md) {
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &__kv {
    width: 100%;
    max-width: 1140px;
    height: auto;
    margin: 30px auto 0 auto;
    padding: 0 70px;
    overflow: hidden;
    @include media(md) {
      width: 100vw;
      margin: 10px 0 0 0;
      padding: 0;
    }
    &__bg {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-height: 456px;
      overflow: hidden;
    }
    &__title {
      position: absolute;
      width: 35vw;
      max-width: 480px;
      height: auto;
      @include media(md) {
        width: 60vw;
      }
    }
  }
}
