@charset "utf-8";
@import "../foundation/foundation_inc";
.l-main {
  position: fixed;
  top: 0;
  display: flex;
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  //min-width: 1280px;
  height: 100%;
  margin:0;
  padding: 0;
  @include media(md) {
    position: relative;
    width: 100%;
    min-width: unset;
  }
}
