@charset "utf-8";
@import "../../../scss/foundation/foundation_inc";
.c-content-header {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 60px 0 0 0;
  margin: 23px auto 0 auto;
  @include media(md) {
    margin: 0;
    padding: 0;
  }
  &__channelName {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 5px 0 20px 0;
    column-gap: 6px;
    color: $color-textLight-main;
    font: $font-desktop-BodyB;
    letter-spacing: 0.05em;
    span {
      font: $font-desktop-cap;
    }
  }
  &__title {
    position: relative;
    display: inline-flex;
    width: 100%;
    margin: 0;
    padding: 0 0 22px 0;
    color: $color-textLight-main;
    font: $font-desktop-H4;
    letter-spacing: 0.05em;
    border-bottom: 1px solid $color-textLight-main;
    @include media(md) {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      margin: 28px 0 8px 0;
      padding: 0;
      color: $color-textLight-main;
      font: $font-mobile-H5;
      letter-spacing: 0.05em;
      flex-shrink: 0;
      //white-space: nowrap;
      border: 0;
    }
  }
}
.c-content-header-sp {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  &__title {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    color: $color-textLight-main;
    font: $font-mobile-H5;
    letter-spacing: 0.05em;
    flex-shrink: 0;
    white-space: nowrap;
    &:after {
      content: "";
      position: relative;
      display: block;
      width: 100%;
      height: 1px;
      margin: 0 0 0 8px;
      background-color: $color-line-gray;
    }
  }
}
