.modal-open {
  height: 100vh;
  overflow-y: hidden;
}
.fadeIn {
  animation: fadeIn 0.9s ease 0.3s 1 normal forwards;
}
.fadeOut {
  animation: fadeOut 0.9s ease 0.3s 1 normal forwards;
}
@keyframes fadeIn {
  0% {opacity: 0}
  100% {opacity: 1}
}
@keyframes fadeOut {
  0% {opacity: 1}
  100% {opacity: 0}
}
@keyframes rotation{
  0%{ transform:rotate(0);}
  100%{ transform:rotate(360deg); }
}
@keyframes savingFadeIn {
  0% {
    display: none;
    opacity: 0
  }
  1% {
    display: flex;
    opacity: 0;
  }
  100% {
    display: flex;
    opacity: 1;
  }
}
@keyframes savingFadeOut {
  0% {
    display: flex;
    opacity: 1;
  }
  99% {
    display: flex;
    opacity: 0;
  }
  100% {
    display: none;
    opacity: 0
  }
}
