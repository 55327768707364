/* You can add global styles to this file, and also import other style files */
@import "./scss/foundation/base";
@import "./scss/foundation/foundation_inc";
@import "./scss/layout/layout_inc";
@import "./scss/object/utility/utilities";
@import "./scss/object/component/c-content-header";
@import "./scss/object/component/c-button";
@import "./scss/object/component/c-loader";
@import "./scss/object/project/p-saving";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
